@media screen and (min-width: 480px) {
  .container {
    width: 426px;
  }

  .header-navigation-list {
    display: none;
  }

  .hamburger {
    display: flex;
  }

  .contact-info {
    display: none;
  }

  .header-address {
    display: none;
  }

  .footer-container {
    padding: 20px;
  }

  .main-section-one {
  }

  .main-title {
  }
  .main-text {
    font-size: 14px;
  }

  .main-button:hover {
  }

  .container-button {
    display: flex;
    justify-content: start;
    align-items: center;
  }
}

@media screen and (min-width: 768px) {
  .container {
    width: 768px;
    padding-right: 15px;
    padding-left: 15px;
  }

  .contact-info {
    display: flex;
  }

  .contact-info a {
    display: flex;
  }

  .container-title {
    margin-top: 50px;
  }

  .main-text {
    font-size: 16px;
  }

  .current {
    display: block;
    margin-bottom: 0px;
  }

  .main-button {
    display: flex;
    margin-right: 50px;
    margin-top: 70px;
  }

  .container-button {
    justify-content: center;
  }

  .hamburger {
    display: none;
  }

  .header-list-address {
    gap: 14px;

    display: flex;
  }

  .header-navigation-list {
    display: flex;
    margin-left: 60px;
    gap: 30px;
  }

  .header-link {
    position: relative;
    padding-top: 24px;
    padding-bottom: 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.02em;
    padding-right: 0px;
    padding-left: 0px;
  }

  .logo-link {
    margin-left: 0px;
  }

  .header-address-link {
    display: flex;

    justify-content: center;
    align-items: center;
    cursor: pointer;

    fill: #c6cdd1;
    width: 40px;
    height: 40px;
    border: 1px solid #ffffff1a;
    border-radius: 10px;

    transition-property: all;
    transition-duration: 0.3s;
  }

  .header-address-link:hover {
    fill: var(--yellow-color);
    transform: scale(1.1);
  }

  .swiper-button-next::after {
    font-size: 44px;
  }

  .swiper-button-prev::after {
    font-size: 44px;
  }
  .container-two-item {
    width: 330px;
  }

  .container-three-item {
    width: 330px;
  }

  .contact-form {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  .container-four-img {
  }

  .container-four-cards-swiper {
    height: 200px;
    padding: 50px;
  }

  .container-four-swiper-name {
    font-size: 20px;
  }

  .container-four-swiper-text {
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: 14px;
  }

  .main-section-four {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  .contact-title {
    font-size: 35px;
  }

  .contact-text {
    font-size: 18px;
  }

  .container-contact-form {
    margin: 0 auto;
    height: 600px;
    width: 600px;
    padding: 60px;
  }

  .container-near-form {
    margin-top: 50px;
    width: 450px;
  }

  .btn {
    font-size: 20px;
  }

  .title-near-form {
    font-size: 20px;
  }

  .text-near-form {
    font-size: 16px;
  }

  .footer-title-text {
    margin-bottom: 20px;
  }

  .footer-logo-svg {
    width: 50px;
    height: 50px;
  }

  .address {
    margin-top: 34px;
  }

  .footer-address {
    font-size: 16px;
    line-height: 1.9;
    letter-spacing: 0.02em;
    color: #ffffff;

    width: 180px;
  }

  .text-list {
    margin-top: 0;
  }
  .footer {
    padding-top: 80px;
  }
  .container {
    width: 768px;
    padding: 0 15px;
    margin: 0 auto;
  }
  .footer-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0px;
    gap: 20px;
  }
  .footer-list-menu {
    margin-left: 142px;
  }
  .footer-list-svg-menu {
    margin-top: 80px;
    height: 40px;
    margin-bottom: 60px;
  }

  .footer-address-link {
    font-size: 20px;
  }

  .footer-svg-menu {
    width: 44px;
    height: 44px;
  }
  .address {
    margin-bottom: 152px;
  }
  .privat {
    display: flex;
    flex-wrap: wrap;
    width: 400px;
  }

  .footer-text {
    font-size: 16px;
    line-height: 1.9;
    letter-spacing: 0.03em;
  }

  .text-list {
    margin-left: auto;
  }
}

@media screen and (min-width: 1280px) {
  .container {
    width: 1206px;
    padding-right: 15px;
    padding-left: 15px;
  }

  .container-title {
    margin-top: 130px;
  }

  .logo {
    font-size: 28px;
    width: 80px;
    height: 80px;
  }

  .header-item-address:not(:last-child) {
    margin-bottom: 0px;
  }

  .header-item-address {
    margin-right: 40px;
  }
  .header-item-address:last-child {
    margin-right: 0px;
  }

  /* .header-address-link {
    display: flex;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.02em;
  } */

  .main-section-one {
    height: 900px;
    /* background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: linear-gradient(
        rgba(24, 38, 51, 0.7),
        rgba(24, 38, 51, 0.7)
      ),
      url(/src/images/background_img/factory-workers-work-wear-yellow-helmets-walking-through-industrial-production-hall_148840-10360.avif); */
    margin-left: auto;
    margin-right: auto;

    padding-top: 112px;
    padding-bottom: 112px;
  }

  .main-title {
    font-size: 40px;
    line-height: 1.07;
    margin-bottom: 48px;
    color: var(--yellow-color);
  }
  .main-text {
    color: var(--white-color);
    font-size: 16px;
    line-height: 1.7;
    letter-spacing: 0.02em;
    font-weight: 400;
    width: 820px;
  }

  .main-button {
    border: 1px solid white;
    height: 50px;
    width: 200px;
    background-color: var(--yellow-color);
    margin-top: 80px;
    font-size: 16px;
    background: transparent;
    color: var(--yellow-color);
    transition: all 0.5s ease;
    margin-right: 200px;
  }

  /* .main-button:hover {
    border: transparent;
    background-color: var(--yellow-color);
    color: var(--white-color);
    transform: scale(1.1);
  } */

  .section-form {
    display: flex;
    justify-content: 'space-between';
  }

  .container-button {
    display: flex;
    justify-content: start;
    align-items: center;
  }

  .container-four-cards-swiper {
    width: 800px;
    height: 250px;
    box-shadow: 0 8px 16px var(--bg-color);
  }

  .container-four-swiper-text {
    width: 500px;
  }

  .container-four-swiper-text {
    font-size: 18px;
  }

  .container-four-img {
    width: 150px;
    margin-right: 50px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: var(--bg-cards-swiper);
  }

  .text-list {
    margin-top: 0;
  }

  .footer {
    padding-top: 150px;
  }
  .footer-list-menu {
    margin-left: 355px;
  }

  .footer-nav {
    margin-left: 0px;
  }

  .footer-logo {
    width: 100px;
  }

  .footer-menu {
    font-size: 18px;
    line-height: 1.33;
  }

  .footer-list-svg-menu {
    margin-top: 0px;
    height: 40px;
    margin-bottom: 0px;
  }

  .footer-row {
    margin-bottom: 120px;
  }

  .footer-address-link {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .privat {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row;
    align-items: center;
  }

  .footer {
    margin: 0 auto;
  }
}
