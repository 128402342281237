@media screen and (min-width: 480px) {
  .container {
    width: 426px;
  }

  .header-navigation-list {
    display: none;
  }

  .hamburger {
    display: flex;
  }

  .contact-info, .header-address {
    display: none;
  }

  .footer-container {
    padding: 20px;
  }

  .main-text {
    font-size: 14px;
  }

  .container-button {
    justify-content: start;
    align-items: center;
    display: flex;
  }
}

@media screen and (min-width: 768px) {
  .container {
    width: 768px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .contact-info, .contact-info a {
    display: flex;
  }

  .container-title {
    margin-top: 50px;
  }

  .main-text {
    font-size: 16px;
  }

  .current {
    margin-bottom: 0;
    display: block;
  }

  .main-button {
    margin-top: 70px;
    margin-right: 50px;
    display: flex;
  }

  .container-button {
    justify-content: center;
  }

  .hamburger {
    display: none;
  }

  .header-list-address {
    gap: 14px;
    display: flex;
  }

  .header-navigation-list {
    gap: 30px;
    margin-left: 60px;
    display: flex;
  }

  .header-link {
    letter-spacing: .02em;
    padding: 24px 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5;
    position: relative;
  }

  .logo-link {
    margin-left: 0;
  }

  .header-address-link {
    cursor: pointer;
    fill: #c6cdd1;
    width: 40px;
    height: 40px;
    border: 1px solid rgba(255, 255, 255, .1);
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    transition-property: all;
    transition-duration: .3s;
    display: flex;
  }

  .header-address-link:hover {
    fill: var(--yellow-color);
    transform: scale(1.1);
  }

  .swiper-button-next:after, .swiper-button-prev:after {
    font-size: 44px;
  }

  .container-two-item, .container-three-item {
    width: 330px;
  }

  .contact-form {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  .container-four-cards-swiper {
    height: 200px;
    padding: 50px;
  }

  .container-four-swiper-name {
    font-size: 20px;
  }

  .container-four-swiper-text {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 14px;
  }

  .main-section-four {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  .contact-title {
    font-size: 35px;
  }

  .contact-text {
    font-size: 18px;
  }

  .container-contact-form {
    height: 600px;
    width: 600px;
    margin: 0 auto;
    padding: 60px;
  }

  .container-near-form {
    width: 450px;
    margin-top: 50px;
  }

  .btn, .title-near-form {
    font-size: 20px;
  }

  .text-near-form {
    font-size: 16px;
  }

  .footer-title-text {
    margin-bottom: 20px;
  }

  .footer-logo-svg {
    width: 50px;
    height: 50px;
  }

  .address {
    margin-top: 34px;
  }

  .footer-address {
    letter-spacing: .02em;
    color: #fff;
    width: 180px;
    font-size: 16px;
    line-height: 1.9;
  }

  .text-list {
    margin-top: 0;
  }

  .footer {
    padding-top: 80px;
  }

  .container {
    width: 768px;
    margin: 0 auto;
    padding: 0 15px;
  }

  .footer-row {
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 0;
    display: flex;
  }

  .footer-list-menu {
    margin-left: 142px;
  }

  .footer-list-svg-menu {
    height: 40px;
    margin-top: 80px;
    margin-bottom: 60px;
  }

  .footer-address-link {
    font-size: 20px;
  }

  .footer-svg-menu {
    width: 44px;
    height: 44px;
  }

  .address {
    margin-bottom: 152px;
  }

  .privat {
    width: 400px;
    flex-wrap: wrap;
    display: flex;
  }

  .footer-text {
    letter-spacing: .03em;
    font-size: 16px;
    line-height: 1.9;
  }

  .text-list {
    margin-left: auto;
  }
}

@media screen and (min-width: 1280px) {
  .container {
    width: 1206px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .container-title {
    margin-top: 130px;
  }

  .logo {
    width: 80px;
    height: 80px;
    font-size: 28px;
  }

  .header-item-address:not(:last-child) {
    margin-bottom: 0;
  }

  .header-item-address {
    margin-right: 40px;
  }

  .header-item-address:last-child {
    margin-right: 0;
  }

  .main-section-one {
    height: 900px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 112px;
    padding-bottom: 112px;
  }

  .main-title {
    color: var(--yellow-color);
    margin-bottom: 48px;
    font-size: 40px;
    line-height: 1.07;
  }

  .main-text {
    color: var(--white-color);
    letter-spacing: .02em;
    width: 820px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.7;
  }

  .main-button {
    height: 50px;
    width: 200px;
    background-color: var(--yellow-color);
    color: var(--yellow-color);
    background: none;
    border: 1px solid #fff;
    margin-top: 80px;
    margin-right: 200px;
    font-size: 16px;
    transition: all .5s;
  }

  .section-form {
    justify-content: "space-between";
    display: flex;
  }

  .container-button {
    justify-content: start;
    align-items: center;
    display: flex;
  }

  .container-four-cards-swiper {
    width: 800px;
    height: 250px;
    box-shadow: 0 8px 16px var(--bg-color);
  }

  .container-four-swiper-text {
    width: 500px;
    font-size: 18px;
  }

  .container-four-img {
    width: 150px;
    margin-right: 50px;
  }

  .swiper-button-prev, .swiper-button-next {
    color: var(--bg-cards-swiper);
  }

  .text-list {
    margin-top: 0;
  }

  .footer {
    padding-top: 150px;
  }

  .footer-list-menu {
    margin-left: 355px;
  }

  .footer-nav {
    margin-left: 0;
  }

  .footer-logo {
    width: 100px;
  }

  .footer-menu {
    font-size: 18px;
    line-height: 1.33;
  }

  .footer-list-svg-menu {
    height: 40px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .footer-row {
    margin-bottom: 120px;
  }

  .footer-address-link {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .privat {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  .footer {
    margin: 0 auto;
  }
}

/*# sourceMappingURL=index.382bee14.css.map */
